<script lang="ts" setup>
const modalStore = useModalStore();
const { updateNutrient } = useNutrientStore();
const { setValidation, required } = useValidation();
const $i18n = useI18n();

const props = defineProps({
  nutrient: {
    type: Object,
    default: () => {},
  },
});

const NUTRIENT_TYPE = [
  {
    value: 'micro',
    label: $i18n.t('nutrient.type.micro'),
  },
  {
    value: 'macro',
    label: $i18n.t('nutrient.type.macro'),
  },
  {
    value: 'vitamin',
    label: $i18n.t('nutrient.type.vitamin'),
  },
  {
    value: 'mineral',
    label: $i18n.t('nutrient.type.mineral'),
  },
  {
    value: 'fatty-acid',
    label: $i18n.t('nutrient.type.fatty-acid'),
  },
  {
    value: 'amino-acid',
    label: $i18n.t('nutrient.type.amino-acid'),
  },
  {
    value: 'cholesterol',
    label: $i18n.t('nutrient.type.cholesterol'),
  },
];

const nutrimentData = reactive({
  label: props.nutrient.label,
  type: props.nutrient.type,
  symbol: props.nutrient.symbol,
  is_used_by_nectar: props.nutrient.is_used_by_nectar,
});

const validation = setValidation(nutrimentData, {
  label: { required },
  type: { required },
  symbol: { required },
});

async function handleUpdateNutriment() {
  await updateNutrient(props.nutrient.id, {
    ...nutrimentData,
    translations: props.nutrient.translations,
  });
  modalStore.closeModal();
}
</script>
<template>
  <BaseModal title="Editer un nutriment">
    <form class="flex flex-col gap-4">
      <BaseInput
        v-model="validation.label.$model"
        label="Label"
        type="text"
        :validation="validation.label"
        required
      />

      <BaseInput
        v-model="validation.symbol.$model"
        label="Symbol"
        type="text"
        :validation="validation.symbol"
      />

      <BaseSelect
        v-model="validation.type.$model"
        label="Type de nutriment"
        :options="NUTRIENT_TYPE"
        :validation="validation.type"
        required
      />

      <div>
        <strong>Traductions</strong>
        <div class="flex flex-col gap-2 pl-4">
          <BaseInput
            v-for="t in props.nutrient.translations"
            v-model="t.label"
            :label="$t(`lang.${t.lang_iso_code}`)"
            type="text"
          />
        </div>
      </div>

      <BaseSwitch
        v-model="nutrimentData.is_used_by_nectar"
        active-text="Inclu dans Nectar Engine"
        inactive-text="Non-inclu dans Nectar Engine"
      />
    </form>

    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton text="Confirmer" @click="handleUpdateNutriment" />
    </template>
  </BaseModal>
</template>
